import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { auth } from '../firebase/config'

const requireAuth = (to,from,next) =>{
  let user = auth.currentUser
  if(!user){
    next({name:'home'})
  }else{
    next()
  }
  

}

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },
  {
    path: '/holiday&experiences/:continent?/:all?',
    name: 'holidayAndExperiences',
    component: () => import(/* webpackChunkName: "about" */ '../views/HolidayAndExperiences.vue')
  },

  {
    path: '/resorts&hotels',
    name: 'resortsAndHotel',
    component: () => import(/* webpackChunkName: "about" */ '../views/ResortsAndHotels.vue')
  },

  {
    path: '/membership',
    name: 'membership',
    component: () => import(/* webpackChunkName: "about" */ '../views/Membership.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard.vue'),
    beforeEnter: requireAuth
  },
 
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },

  
  {
    path: '/destination/:id',
    name: 'DestinationDetails',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/DestinationDetails.vue')
  },
  {
    path: '/attraction/:id',
    name: 'AttractionDetails',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AttractionDetails.vue')
  },
  {
    path: '/resort/:id',
    name: 'HotelDetails',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/HotelDetails.vue')
  },
  {
    path: '/room/:id',
    name: 'RoomDetails',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/RoomDetails.vue')
  }

  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
