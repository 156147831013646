import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
//import LottieVuePlayer from "@lottiefiles/vue-lottie-player";
import Vue3Lottie from 'vue3-lottie'
import { auth } from './firebase/config'
import { onAuthStateChanged } from 'firebase/auth'

let app
onAuthStateChanged(auth, () => {
    if(!app){
        app =  createApp(App)
        .use(router)
        .use(Vue3Lottie)
        .mount('#app')
    }


})


