<template>
  <section class="globe">
    <div class="globe__head">
      <h2 class="globe__title">"FIND YOUR DESTINATION"</h2>
    </div>
    <div class="globe__body">
      <div class="globe__grid">
        <router-link
          :to="{ name: 'holidayAndExperiences', params: { continent:'Europe' ,all:false} }"
        >
          <div class="globe__cont">
            <img
              src="../assets/europe.svg"
              class="globe__cont-img"
              alt="europe"
            />
            <h3 class="globe__cont-name">Europe</h3>
          </div>
        </router-link>



        <router-link
          :to="{ name: 'holidayAndExperiences', params: { continent:'Asia&Middle-East',all:false } }"
        >
          <div class="globe__cont">
          <img
            src="../assets/asia-middle-east.svg"
            class="globe__cont-img"
            alt="europe"
          />
          <h3 class="globe__cont-name">Asia & Middle East</h3>
        </div>
        </router-link>


        <router-link
          :to="{ name: 'holidayAndExperiences', params: { all:false,continent:'Africa'} }"
        >
          <div class="globe__cont">
          <img
            src="../assets/africa.svg"
            class="globe__cont-img"
            alt="europe"
          />
          <h3 class="globe__cont-name">Africa</h3>
        </div>
        </router-link>


         <router-link
          :to="{ name: 'holidayAndExperiences', params: { all:false,continent:'Australia-NewZeaLand&Pacific-Island' } }"
        >
           <div class="globe__cont">
          <img
            src="../assets/aus-new-pacific.svg"
            class="globe__cont-img"
            alt="europe"
          />
          <h3 class="globe__cont-name">
            Australia, New Zealand & Pacific islands
          </h3>
        </div>
        </router-link>

        
        
       
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
<style lang="scss">
@forward "../../scss/globals";
@use "../../scss/util" as u;
.globe {
  width: min(100% - u.rem(16), u.rem(1600));
  height: auto;
  margin-inline: auto;
  margin-top: u.rem(32);

  border-top-left-radius: u.rem(25);
  border-top-right-radius: u.rem(25);
  border-bottom-left-radius: u.rem(25);
  border-bottom-right-radius: u.rem(25);
  margin-bottom: u.rem(8);
  background-color: white;

  @include u.breakpoint(medium) {
    width: min(100% - u.rem(40), u.rem(1600));
    //   margin-top: -3%;
    padding: u.rem(18);
  }
  &__head {
    display: flex;
    height: auto;
    justify-content: center;
    width: 100%;
  }

  &__title {
    padding-left: u.rem(24);
    padding-top: u.rem(32);
    padding-bottom: u.rem(32);

    font-size: u.rem(14);
    @include u.breakpoint(medium) {
      font-size: u.rem(16);
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    gap: u.rem(8);
    width: 100%;
    padding: u.rem(16);
    margin-inline: auto;
    @include u.breakpoint(large) {
      grid-template-columns: 25% 25% 25% 25%;
      grid-template-rows: auto;
    }
  }
  &__cont {
    text-align: center;
  }

  &__cont-img {
    width: 25vw;
    height: 25vw;

    @include u.breakpoint(large) {
      width: 15vw;
      height: 15vw;
    }
  }
  &__cont-name {
    padding: u.rem(16);
    font-size: u.rem(14);
    font-weight: 500;
  }
}
</style>