<template>
  <section class="footer">
            <img src="../assets/logo.svg" alt="" class="footer__logo">
            <h2 class="footer__tag">© mid 2022 www.hotelexpressindia.in. All Rights Reserved</h2>
            <!-- <h3 class="footer__terms"> Terms and conditions</h3> -->

        </section>
</template>

<script>
export default {

}
</script>

<style lang="scss">
@forward "../../scss/globals";
@use "../../scss/util" as u;

.footer{
    width: min(100% - u.rem(16), u.rem(1600));
    text-align: center;
    height: auto;
    margin-inline: auto;
    margin-top: u.rem(32);

    border-top-left-radius: u.rem(25);
    border-top-right-radius: u.rem(25);
    border-bottom-left-radius: u.rem(25);
    border-bottom-right-radius: u.rem(25);
    margin-bottom: u.rem(8);
    background-color: white;


    @include u.breakpoint(medium) {
        width: min(100% - u.rem(40), u.rem(1600));
     //   margin-top: -3%;
        padding: u.rem(18);
    }

    &__logo{
        width: u.rem(150);
        height: u.rem(150);
    }
    &__tag{
        padding: u.rem(16);
        padding-bottom: u.rem(4);
        font-size: u.rem(14);
    }

    &__terms{
        padding-bottom: u.rem(16);

        font-size: u.rem(12);
        color: var(--color-primary);
    }
}

</style>