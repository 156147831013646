<template>
  <section class="reviews">
    <div class="reviews__head">
      <h2 class="reviews__title">"WHAT OUR CUSTOMERS SAY ABOUT US"</h2>
    </div>
    <div class="reviews__body">
      <div class="reviews__grid">
        <div class="reviews__cont" v-for="doc in reviews"  :key="doc.id">
          <img :src="doc.customerImage" alt="" class="reviews__cust-image" />
          <h3 class="reviews__cust-name">{{doc.customerName}}</h3>
          <p class="reviews__review">
           {{doc.review}}
          </p>
        </div>
        
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from "vue";
import { db } from "../firebase/config";
import { collection, getDocs } from "firebase/firestore";
export default {
async setup(){
      const reviews = ref([]);

    const getReviews = async () => {
      const result = [];
      const querySnapshot = await getDocs(collection(db, "Reviews"));
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        result.push(doc.data());
      });
      reviews.value = result;
      
    }
    getReviews()

    return{reviews}

}


};
</script>


<style lang="scss">
@forward "../../scss/globals";
@use "../../scss/util" as u;
.reviews{
    width: min(100% - u.rem(16), u.rem(1600));
    height: auto;
    margin-inline: auto;
    margin-top: u.rem(32);

    border-top-left-radius: u.rem(25);
    border-top-right-radius: u.rem(25);
    border-bottom-left-radius: u.rem(25);
    border-bottom-right-radius: u.rem(25);
    margin-bottom: u.rem(8);
    background-color: white;

    @include u.breakpoint(medium) {
        width: min(100% - u.rem(40), u.rem(1600));
     //   margin-top: -3%;
        padding: u.rem(18);
    }
    &__head {
        display: flex;
        height: auto;
        justify-content: center;
        width: 100%;
    }
    &__title {
        //padding-left: u.rem(24);
        padding-top: u.rem(32);
        padding-bottom: u.rem(32);
        text-align: center;
        padding-left: u.rem(16);
        padding-right: u.rem(16);

        font-size: u.rem(14);

        @include u.breakpoint(medium) {
         
            font-size: u.rem(16);
        }

        
        

    }

    &__grid {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: auto;
        gap: u.rem(8);
        width: 100%;
        padding: u.rem(16);
        margin-inline: auto;
        @include u.breakpoint(large) {
            grid-template-columns:33.33% 33.33% 33.33%;
        grid-template-rows: auto;
         
        }
       

    }
    &__cont{
        text-align: center;
        padding: u.rem(16);
    }

    &__cust-image{
        width: 20vw;
        height: 20vw;
        border-radius: 50%;
        margin: u.rem(16);
            object-fit: cover;
        @include u.breakpoint(medium) {
            width: 10vw;
            height: 10vw;
         
        }

        @include u.breakpoint(large) {
            width: 6vw;
            height: 6vw;
         
        }

    }
    &__cust-name{
        padding: u.rem(16);
        font-size: u.rem(14);
        font-weight: 600;
    }
    &__review{
        margin-bottom: u.rem(16);
        font-size: clamp(u.rem(12), 1vw, u.rem(14));
        word-wrap: break-word;

        @include u.breakpoint(medium) {
            margin-top: u.rem(24);
            // margin-bottom: u.rem(24);
          
            width: 75%;
            margin: auto;
            margin-bottom: u.rem(24);
        }

        @include u.breakpoint(large) {
          
        }

    }
    
}
</style>