<template>
  <section class="slider">
    <div class="slider__container">
      <div v-for="doc in slider.slideImages" :key="doc" class="slider__slide">
        <img class="slider__img" v-bind:src="doc" />
      </div>
    </div>
  </section>
  <section class="tags">
    <h1 class="tags__title">
      {{ slider.title }}
    </h1>
    <p class="tags__details">
      {{ slider.description }}
    </p>
    <router-link :to="{ name: 'holidayAndExperiences',params:{all:true,continent:'ALL'}}">
      <button class="tags__button">VIEW EXPERIENCES</button>
    </router-link>
  </section>
</template>

<script>
// firebase import
import { ref } from "vue";
import { db } from "../firebase/config";
import { collection, getDocs } from "firebase/firestore";

const loadHeader = async () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        name: "Matt Maribojoc",
        pic: "https://cdn-images-1.medium.com/fit/c/100/100/2*EcZb9cndrhTF7_d74dv2Fg.png",
        bio: "I run a VueJS community over at https://learnvue.co, develop web sites, and post whatever I find cool on the Internet.",
      });
    }, 1000);
  });
};

export default {
  name: "TopSlider",
   async setup() {
    const slider = ref({});
    const loaded = ref(false);
      

    // onMounted(() => {
    //   console.log("Mounted");
    //   //  mobileNavState.value = 0
    // });

    const getSlider = async () => {
      const result = [];
      const querySnapshot = await getDocs(collection(db, "TopSlider"));
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        result.push(doc.data());
      });
      slider.value = result[0];
      loaded.value = true;
    };
    getSlider();
    const userData = ref(await loadHeader());

    return { slider, loaded, userData };
  },
};
</script>

<style lang="scss">
@forward "../../scss/globals";
@use "../../scss/util" as u;

.slider {
  position: relative;
  width: min(100% - u.rem(16), u.rem(1600));

  height: 50vh;
  overflow: hidden;
  margin-inline: auto;
  margin-top: u.rem(8);
  border-top-left-radius: u.rem(25);
  border-top-right-radius: u.rem(25);
  border-bottom-left-radius: u.rem(25);
  border-bottom-right-radius: u.rem(25);
  margin-bottom: u.rem(8);

  @include u.breakpoint(medium) {
    margin-top: u.rem(16);
    width: min(100% - u.rem(40), u.rem(1600));

    height: 60vh;
    border-bottom-left-radius: u.rem(0);
    border-bottom-right-radius: u.rem(0);
  }
  @include u.breakpoint(large) {
    //  width: min(100% - u.rem(40), u.rem(1700));

    height: 65vh;
  }

  &__container {
    position: absolute;
    width: 100% * 10;
    top: 0;
    left: 0;
    overflow: hidden;
    animation: slider 26s infinite;
  }

  &__slide {
    position: relative;
    width: 10%;
    height: auto;
    float: left;
    overflow: hidden;
  }

  &__img {
    display: block;
    width: 100%;
    height: 50vh;
    object-fit: cover;
    @include u.breakpoint(medium) {
      height: 60vh;
    }
    @include u.breakpoint(large) {
      //  width: min(100% - u.rem(40), u.rem(1700));

      height: 65vh;
    }
  }

  @keyframes slider {
    0%,
    25%,
    100% {
      left: 0;
    }

    30%,
    55% {
      left: -100%;
    }

    60%,
    85% {
      left: -200%;
    }
  }
}

.tags {
  position: relative;
  width: min(100% - u.rem(16), u.rem(1600));
  height: auto;
  overflow: hidden;
  margin-inline: auto;
  padding: u.rem(16);
  background-color: white;
  border-radius: u.rem(25);
  text-align: center;

  @include u.breakpoint(medium) {
    width: min(100% - u.rem(40), u.rem(1600));
    margin-top: -3%;
    padding: u.rem(18);
  }

  @include u.breakpoint(large) {
    padding: u.rem(24);
    margin-top: -5%;
    border-radius: u.rem(50);
  }

  &__title {
    margin-top: u.rem(16);
    margin-bottom: u.rem(16);
    font-size: u.rem(16);
    width: 75%;
    margin-left: auto;
    margin-right: auto;

    @include u.breakpoint(medium) {
      margin-top: u.rem(24);
      margin-bottom: u.rem(24);
      font-size: u.rem(16);
    }

    @include u.breakpoint(large) {
      font-size: u.rem(16);
    }
    @include u.breakpoint(xlarge) {
      font-size: u.rem(18);
    }
  }

  &__details {
    margin-bottom: u.rem(16);
    font-size: u.rem(12);

    @include u.breakpoint(medium) {
      margin-top: u.rem(24);
      // margin-bottom: u.rem(24);
      font-size: u.rem(14);
      width: 75%;
      margin: auto;
      margin-bottom: u.rem(24);
    }

    @include u.breakpoint(large) {
      font-size: u.rem(16);
    }
  }

  &__button {
    padding: u.rem(16);
    background: var(--color-primary);
    font-size: u.rem(12);
    border-radius: u.rem(50);
    box-shadow: 0px, 0px 0px #41414132;
    border: none;
    font-family: u.$font;
    font-weight: 600;
    color: var(--card-color-on-primary);
    padding-left: u.rem(24);
    padding-right: u.rem(24);
    margin-bottom: u.rem(16);

    @include u.breakpoint(medium) {
      font-size: u.rem(14);
    }
  }
}
</style>