<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <header>
    <div class="header">
      <div class="header__col-1-social"></div>

      <div class="header__col-2-logo">
        <a class="header__logo_img" href="#"
          ><img
            class="header__logo_img"
            src="../assets/logo.svg"
            alt="Hotel Express India logo"
        /></a>
      </div>

      <div class="header__col-3-mobilenav">
        <a class="header__mobilenav" href="#" @click="showHideMobileNav(1)"
          ><img
            class="header__mob-nav-img"
            src="../assets/mobile_menu_open.svg"
            alt="Hotel Express India logo"
        /></a>
      </div>

      <div class="header__col-4-contact">
       
      </div>
    </div>

    <div class="nav">
      <div class="nav__wrapper">
        <ul class="nav__links">
          <router-link to="/">
            <li class="nav__link"><i class="fa-solid fa-house"></i>Home</li>
          </router-link>

          <router-link :to="{ name: 'holidayAndExperiences',params:{all:true,continent:'ALL'}}">
            <li class="nav__link">
              <i class="fa-solid fa-umbrella-beach"></i>Holiday & Experiences
            </li>
          </router-link>

          <router-link to="/resorts&hotels">
            <li class="nav__link">
              <i class="fa-solid fa-hotel"></i>Resorts & Hotels
            </li>
          </router-link>

          <router-link to="/membership">
            <li class="nav__link">
              <i class="fa-solid fa-handshake"></i>Membership
            </li>
          </router-link>

          <router-link v-if="!user" to="/login">
            <li class="nav__link">
              <i class="fa-solid fa-arrow-right-to-bracket"></i>Login
            </li></router-link
          >
          <router-link v-if="user" to="/dashboard">
            <li class="nav__link">
              <i class="fa-solid fa-user-secret"></i> Account
            </li></router-link
          >
        </ul>
      </div>
    </div>

    <section
      class="mobileNav"
      :class="
        mobileNavState === 1
          ? 'mobileNav__slide-in'
          : mobileNavState === 2
          ? 'mobileNav__slide-out'
          : ''
      "
    >
      <div class="mobileNav__head">
        <a class="header__mobilenav" href="#" @click="showHideMobileNav(2)"
          ><img
            class="header__mob-nav-img"
            src="../assets/mobile_menu_close.svg"
            alt="Hotel Express India logo"
        /></a>
      </div>
      <div class="mobileNav__wrapper">
        <ul class="mobileNav__links">
          <router-link to="/">
            <li class="mobileNav__link" @click="showHideMobileNav(2)">
              <i class="fa-solid fa-house"></i>Home
            </li>
          </router-link>

          <router-link :to="{ name: 'holidayAndExperiences',params:{all:true,continent:'ALL'}}">
            <li class="mobileNav__link" @click="showHideMobileNav(2)">
              <i class="fa-solid fa-umbrella-beach"></i>Holiday & Experiences
            </li>
          </router-link>

          <router-link to="/resorts&hotels">
            <li class="mobileNav__link" @click="showHideMobileNav(2)">
              <i class="fa-solid fa-hotel"></i>Resorts & Hotels
            </li>
          </router-link>

          <router-link to="/membership">
            <li class="mobileNav__link" @click="showHideMobileNav(2)">
              <i class="fa-solid fa-handshake"></i>Membership
            </li>
          </router-link>

          <router-link to="/login"  v-if="!user" >
            <li class="mobileNav__link" @click="showHideMobileNav(2)">
              <i class="fa-solid fa-arrow-right-to-bracket"></i>Login
            </li></router-link>

            <router-link to="/dashboard" v-if="user">
            <li class="mobileNav__link" @click="showHideMobileNav(2)">
              <i class="fa-solid fa-user-secret"></i>Account
            </li></router-link>
        </ul>
      </div>
    </section>
  </header>
</template>

<script>
// firebase import
import { ref } from "vue";
import getUser from '../composables/getUser'


const loadHeader = async () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        name: "Matt Maribojoc",
        pic: "https://cdn-images-1.medium.com/fit/c/100/100/2*EcZb9cndrhTF7_d74dv2Fg.png",
        bio: "I run a VueJS community over at https://learnvue.co, develop web sites, and post whatever I find cool on the Internet.",
      });
    }, 1000);
  });
}

export default {
  name: "Header",

 async setup() {
    const showMobileNav = ref();
    const mobileNavState = ref(0);
   const userData = ref(await loadHeader());
   



   const { user } = getUser();
    console.log('uuuuueeerrrr', user.value)

    
  


  

    const showHideMobileNav = async (value) => {
      mobileNavState.value = value;

      let currentValue = showMobileNav.value;
      if (currentValue) {
        showMobileNav.value = false;
      } else {
        showMobileNav.value = true;
      }
    };

    return { showMobileNav, showHideMobileNav, mobileNavState,userData,user };
  },
};
</script>


<style lang="scss">
@forward "../../scss/globals";
@use "../../scss/util" as u;

a {
  color: var(--text-color);
}

.header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: u.rem(8);
  width: min(100% - u.rem(40), u.rem(1600));
  margin-inline: auto;
  //  padding-top: u.rem(8);
  //  padding-bottom: u.rem(8);

  &__col-1-social {
    display: block;
  }

  &__col-2-logo {
    display: block;
    margin: auto;
  }

  &__col-3-mobilenav {
    display: block;
    //margin-right:auto;
    margin: auto;
    margin-right: 0%;

    @include u.breakpoint(medium) {
      display: none;
    }
  }

  &__col-4-contact {
    display: block;
    margin: auto;
    @include u.breakpoint-down(small) {
      display: none;
    }
  }

  &__logo_img {
    width: calc(70px + 5vw);
    height: calc(70px + 5vw);
    @include u.breakpoint(medium) {
      width: calc(70px + 2vw);
      height: calc(70px + 2vw);
    }
  }

  &__mob-nav-img {
    width: calc(60px + 3vw);
    height: calc(60px + 3vw);
  }

  &__call {
    display: inline-block;
    visibility: visible;
    padding-top: u.rem(8);
    padding-bottom: u.rem(8);
    padding-left: u.rem(8);
    padding-right: u.rem(16);
    //  background-color: aqua;
    border-radius: u.rem(50);
    color: var(--text-color);
    text-align: center;
    margin: auto;
    vertical-align: middle;
    // -webkit-transition: width 0.2s ease-in-out;
    // -moz-transition: width 0.2s ease-in-out;
    // -o-transition: width 0.2s ease-in-out;
    // -ms-transition: width 0.2s ease-in-out;
    // transition: visibility 0s, opacity 0.5s ease-in-out;
    font-size: clamp(u.rem(10), u.rem(2) + 1vw, u.rem(14));

    @include u.breakpoint-down(small) {
      visibility: collapse;
    }
  }

  &__phone-img {
    width: clamp(u.rem(14), u.rem(16) + 1vw, u.rem(24));
    height: clamp(u.rem(14), u.rem(16) + 1vw, u.rem(24));
    vertical-align: middle;
    padding: u.rem(4);
  }
}
.nav {
  height: calc(30px + 2vh);

  -webkit-transition: width 0.3s ease-in-out;
  -moz-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  -ms-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
  background-color: white;
  border-radius: u.rem(50);
  width: min(100% - u.rem(40), u.rem(1600));
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: u.rem(8);

  li:hover {
    color: var(--color-primary);
    cursor: pointer;
    font-weight: 800;
  }

  .router-link-active {
    color: var(--color-primary);
    cursor: pointer;
    font-weight: 700;
  }

  .router-link-exact-active {
    color: var(--color-primary);
    cursor: pointer;
    font-weight: 700;
  }

  @include u.breakpoint-down(small) {
    display: none;
  }

  &__wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0 1%;
  }

  &__links {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    list-style: none;
    margin: 0 auto;
    height: calc(30px + 2vh);
    text-decoration: none;
    text-transform: uppercase;
    padding: 0px;
  }

  i {
    padding: 0px u.rem(12);
  }
}

.mobileNav {
  top: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.4);
  transform: translatex(100%);
  --webkit-transform: translatex(100%);
  z-index: 5;
  
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(26px);

  &__slide-in {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
  }

  &__slide-out {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
  }

  .router-link-active {
    color: var(--color-primary);
    cursor: pointer;
    font-weight: 700;
  }

  .router-link-exact-active {
    color: var(--color-primary);
    cursor: pointer;
    font-weight: 700;
  }

  @keyframes slide-in {
    100% {
      transform: translateX(0%);
    }
  }

  @-webkit-keyframes slide-in {
    100% {
      -webkit-transform: translateX(0%);
    }
  }

  @keyframes slide-out {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(100%);
    }
  }

  @-webkit-keyframes slide-out {
    0% {
      -webkit-transform: translateX(0%);
    }
    100% {
      -webkit-transform: translateX(100%);
    }
  }

  &__head {
    display: flex;
    height: auto;
    justify-content: end;
    width: 100%;
    padding-top: 16px;
    padding-right: 16px;
  }
  &__links {
    // display: flex;
    width: 100%;

    text-align: center;
    list-style: none;
    margin: auto;
    height: calc(30px + 2vh);
    text-decoration: none;
    text-transform: uppercase;
    padding: 0;
  }
  &__link {
    // padding: u.rem(32);
    font-size: u.rem(14);
    width: 85%;

    background: #ffff;
    border-radius: 50px;
    margin-top: 5vh;
    margin-left: auto;
    margin-right: auto;

    text-align: start;
    padding: 1rem;
  }
  i {
    padding: 0px u.rem(12);
  }
}
</style>