<template>
  <div class="skleton-top-destinations">
    <div class="skleton-top-destinations__head">
      <div
        class="
          skleton-top-destinations__title skleton-top-destinations__skeleton-box
        "
      ></div>
      <div
        class="
          skleton-top-destinations__view-all
          skleton-top-destinations__skeleton-box
        "
      ></div>
    </div>
    <div class="skleton-top-destinations__head">
      <div
        class="
          skleton-top-destinations__link skleton-top-destinations__skeleton-box
        "
      ></div>
      <div
        class="
          skleton-top-destinations__link skleton-top-destinations__skeleton-box
        "
      ></div>
      <div
        class="
          skleton-top-destinations__link skleton-top-destinations__skeleton-box
        "
      ></div>
      <div
        class="
          skleton-top-destinations__link skleton-top-destinations__skeleton-box
        "
      ></div>
      <div
        class="
          skleton-top-destinations__link skleton-top-destinations__skeleton-box
        "
      ></div>
    </div>
    <div class="skleton-top-destinations__cover">
      <div class="skleton-top-destinations__skeleton-box-f"></div>
    </div>
    <div class="skleton-top-destinations__grid">
      <div class="skleton-top-destinations__details">
        <div class="skleton-top-destinations__details-flex-container">
          <div class="skleton-top-destinations__details-left">
            <div
              class="
                skleton-top-destinations__name
                skleton-top-destinations__skeleton-box
              "
            ></div>
            <div
              class="
                skleton-top-destinations__attr
                skleton-top-destinations__skeleton-box
              "
            ></div>
            <div
              class="
                skleton-top-destinations__hotel
                skleton-top-destinations__skeleton-box
              "
            ></div>
          </div>

          <div class="skleton-top-destinations__details-right">
            <div
              class="
                skleton-top-destinations__m-view-but
                skleton-top-destinations__skeleton-box
              "
            ></div>
          </div>
        </div>
        <div
          class="
            skleton-top-destinations__description
            skleton-top-destinations__skeleton-box
          "
        ></div>
      </div>

      <div class="skleton-top-destinations__attraction-div">
        <div class="skleton-top-destinations__attr-head">
          <div
            class="
              skleton-top-destinations__title
              skleton-top-destinations__skeleton-box
            "
          ></div>
          <div
            class="
              skleton-top-destinations__w-view-but
              skleton-top-destinations__skeleton-box
            "
          ></div>
        </div>
        <div class="skleton-top-destinations__attr-item-container">
          <div
            class="
              skleton-top-destinations__attr-item
              skleton-top-destinations__skeleton-box
            "
          ></div>
          <div
            class="
              skleton-top-destinations__attr-item
              skleton-top-destinations__skeleton-box
            "
          ></div>
          <div
            class="
              skleton-top-destinations__attr-item
              skleton-top-destinations__skeleton-box
            "
          ></div>
          <div
            class="
              skleton-top-destinations__attr-item
              skleton-top-destinations__skeleton-box
            "
          ></div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@forward "../../../scss/globals";
@use "../../../scss/util" as u;

.skleton-top-destinations {
  position: relative;
  width: min(100% - u.rem(16), u.rem(1600));
  height: auto;
  margin-inline: auto;
  margin-top: u.rem(32);
  border-top-left-radius: u.rem(25);
  border-top-right-radius: u.rem(25);
  border-bottom-left-radius: u.rem(25);
  border-bottom-right-radius: u.rem(25);
  margin-bottom: u.rem(8);
  background-color: white;
  animation: fullView 0.5s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);

  @include u.breakpoint(medium) {
    width: min(100% - u.rem(40), u.rem(1600));
    //   margin-top: -3%;
    padding: u.rem(18);
  }

  &__head {
    display: flex;
    height: auto;
    justify-content: space-between;
    width: 100%;
  }

  &__title {
    height: u.rem(42);
    padding-left: u.rem(24);
    padding-top: u.rem(24);
    margin: u.rem(16);
    margin-left: 0;
    border-radius: u.rem(50);
    width: 20%;

    @include u.breakpoint(medium) {
      width: 15%;
    }
    @include u.breakpoint(medium) {
      width: 15%;
    }
  }

  &__view-all {
    height: u.rem(42);
    padding-left: u.rem(24);
    padding-top: u.rem(24);
    border-radius: u.rem(50);
    margin: u.rem(16);
    margin-right: 0;
    width: 15%;

    @include u.breakpoint(medium) {
      width: 10%;
    }
    @include u.breakpoint(medium) {
      width: 10%;
    }
  }

  &__link {
    display: flex;
    height: u.rem(32);
    margin: u.rem(16);
    margin-left: 0;
    border-radius: u.rem(32);

    width: 15%;
    align-items: center;
  }

  &__cover {
    display: block;
    width: 100%;
    height: 50vh;
    border-radius: u.rem(20);
    margin-top: u.rem(24);
  }

  &__grid {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    gap: u.rem(8);
    width: 100%;
    padding: u.rem(16);
    margin-inline: auto;
    @include u.breakpoint(large) {
      grid-template-columns: 50% 50%;
      grid-template-rows: auto;
    }
  }

  &__details-flex-container {
    display: flex;
    height: auto;
    justify-content: space-between;
    width: 100%;
  }

  &__name {
    width: u.rem(200);
    height: u.rem(32);
    margin-top: 0;
    margin-bottom: u.rem(8);
    margin-left: 0;
    border-radius: u.rem(50);
  }

  &__attr {
    width: u.rem(150);
    height: u.rem(25);
    margin-left: 0;
    border-radius: u.rem(50);
    margin-top: u.rem(8);
  }

  &__hotel {
    width: u.rem(150);
    height: u.rem(25);
    margin-left: 0;
    border-radius: u.rem(50);
    margin-top: u.rem(8);
  }

  &__m-view-but {
    width: u.rem(100);
    height: u.rem(32);
    margin-top: 0;
    margin-bottom: u.rem(8);
    margin-right: 0;
    border-radius: u.rem(50);

    @include u.breakpoint(large) {
      visibility: hidden;
    }
  }
  &__w-view-but {
    width: u.rem(100);
    height: u.rem(32);
    margin-top: 0;
    margin-bottom: u.rem(8);
    margin-right: 0;
    border-radius: u.rem(50);
    visibility: hidden;

    @include u.breakpoint(large) {
      visibility: visible;
    }
  }

  &__description {
    width: 99%;
    height: u.rem(150);
    margin-left: 0;
    margin-top: u.rem(8);
    border-radius: u.rem(20);
  }

  &__attr-head {
    display: flex;
    height: auto;
    justify-content: space-between;
    width: 100%;
  }

  &__title {
    width: u.rem(200);
    height: u.rem(32);
    margin-top: 0;
    margin-bottom: u.rem(8);
    margin-left: 0;
    border-radius: u.rem(50);
  }

  &__attr-item-container {
    display: inline-block;
    height: 100%;
    width: 200%;
    margin-inline: auto;
    overflow-x: hidden;
}

  &__attr-item {
    display: inline-block;
    margin-top: 4%;
    
    margin-left: auto;
    margin-right: 16px;
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 0.625rem;
}

  &__skeleton-box-f {
    width: 100%;
    height: 100%;
    border-radius: u.rem(20);
    animation: shimmer 2s infinite linear;
    background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
    background-size: 3000px 100%;
  }

  &__skeleton-box {
    animation: shimmer-tags 2s infinite linear;
    background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
    background-size: 3000px 100%;
  }

  @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
}
</style>