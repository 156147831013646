// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFunctions } from 'firebase/functions';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAaU-I7HrjmlPJwuF12eiai1rsN8hmGqy4",
  authDomain: "hotelexpressindia-54050.firebaseapp.com",
  projectId: "hotelexpressindia-54050",
  storageBucket: "hotelexpressindia-54050.appspot.com",
  messagingSenderId: "989411485534",
  appId: "1:989411485534:web:b400b696461f99a9a4d1cf",
  measurementId: "G-K260DF85TF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);


// init firestore service
const db = getFirestore();
const functions = getFunctions(app);

// Initialize Cloud Storage and get a reference to the service
const storage = getStorage(app);
  
  export { db , auth ,functions,storage }