<template>
  <div class="top-slider-skeleton">
    <div class="top-slider-skeleton__skeleton-box"></div>
  </div>
  <div class="tags-skeleton">
    <div class="tags-skeleton__title tags-skeleton__skeleton-box"></div>
    <div class="tags-skeleton__details tags-skeleton__skeleton-box"></div>
    <div class="tags-skeleton__button tags-skeleton__skeleton-box"></div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@forward "../../../scss/globals";
@use "../../../scss/util" as u;

.top-slider-skeleton {
  position: relative;
  width: min(100% - u.rem(16), u.rem(1600));
  background: #fff;

  height: 50vh;
  overflow: hidden;
  margin-inline: auto;
  margin-top: u.rem(8);
  border-top-left-radius: u.rem(25);
  border-top-right-radius: u.rem(25);
  border-bottom-left-radius: u.rem(25);
  border-bottom-right-radius: u.rem(25);
  margin-bottom: u.rem(8);
  animation: fullView 0.5s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);

  @include u.breakpoint(medium) {
    margin-top: u.rem(16);
    width: min(100% - u.rem(40), u.rem(1600));

    height: 60vh;
    border-bottom-left-radius: u.rem(0);
    border-bottom-right-radius: u.rem(0);
  }
  @include u.breakpoint(large) {
    //  width: min(100% - u.rem(40), u.rem(1700));

    height: 65vh;
  }

  &__skeleton-box {
    width: 100%;
    height: 100%;
    animation: shimmer 2s infinite linear;
    background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
    background-size: 3000px 100%;
  }

  @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
}

.tags-skeleton {
  display: -moz-box;
  position: relative;
  width: min(100% - u.rem(16), u.rem(1600));
  height: auto;
  overflow: hidden;
  margin-inline: auto;
  padding: u.rem(16);
  background-color: white;
  border-radius: u.rem(25);
  text-align: center;
  animation: fullView 0.5s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);

  @include u.breakpoint(medium) {
    width: min(100% - u.rem(40), u.rem(1600));
    margin-top: -3%;
    padding: u.rem(18);
  }

  @include u.breakpoint(large) {
    padding: u.rem(24);
    margin-top: -5%;
    border-radius: u.rem(50);
  }

  &__title {
    margin-top: u.rem(16);
    margin-bottom: u.rem(16);
    height: u.rem(42);
    border-radius: u.rem(50);
    width: 90%;
    margin-left: auto;
    margin-right: auto;

    @include u.breakpoint(medium) {
      width: 75%;
      margin-top: u.rem(24);
      // margin-bottom: u.rem(24);
      font-size: u.rem(16);
    }

    @include u.breakpoint(large) {
      width: 75%;
      //font-size: u.rem(16);
    }
    @include u.breakpoint(xlarge) {
      width: 75%;
      // font-size: u.rem(18);
    }
  }

  &__details {
    margin: auto;
    margin-bottom: u.rem(16);

    height: u.rem(100);
    width: 90%;
    border-radius: u.rem(20);

    @include u.breakpoint(medium) {
      width: 75%;
      margin-top: u.rem(24);
      // margin-bottom: u.rem(24);
      font-size: u.rem(14);
      width: 75%;
      margin: auto;
      margin-bottom: u.rem(24);
    }

    @include u.breakpoint(large) {
      width: 75%;
      font-size: u.rem(16);
    }
  }

  &__button {
    height: u.rem(50);
    width: 75%;
    margin: auto;

    padding: u.rem(16);
    background: var(--color-primary);
    font-size: u.rem(12);
    border-radius: u.rem(50);

    padding-left: u.rem(24);
    padding-right: u.rem(24);
    margin-bottom: u.rem(16);

    @include u.breakpoint(medium) {
      font-size: u.rem(14);
      width: 35%;
    }
  }

  &__skeleton-box {
    animation: shimmer-tags 2s infinite linear;
    background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
    background-size: 3000px 100%;
  }

  @keyframes shimmer-tags {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
}
</style>