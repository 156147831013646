<template>
  <div class="about">
    <div class="about__head">
      <h2 class="about__title">"ABOUT US"</h2>
    </div>
    <div class="body">
      <p v-for="doc in allPara" :key="doc.id" class="about-text">
       {{doc.p}}
      </p>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { db } from "../firebase/config";
export default {
  setup() {
    const allPara = ref([]);

  const tpSlider = onSnapshot(
      doc(db, "about", "qTPDpwoGC1gs2UKMYkoL"),
      (doc) => {
        console.log("Current data: ", doc.data());
        let d = doc.data();
        

        allPara.value = d.paras;
      }
    );

    return {
      allPara,
    };
  },
};
</script>

<style lang="scss">
@forward "../../scss/globals";
@use "../../scss/util" as u;

.about {
  width: min(100% - u.rem(16), u.rem(1600));
  height: auto;
  margin-inline: auto;
  margin-top: u.rem(32);

  border-top-left-radius: u.rem(25);
  border-top-right-radius: u.rem(25);
  border-bottom-left-radius: u.rem(25);
  border-bottom-right-radius: u.rem(25);
  margin-bottom: u.rem(8);
  background-color: white;

  @include u.breakpoint(medium) {
    width: min(100% - u.rem(40), u.rem(1600));
    //   margin-top: -3%;
    padding: u.rem(18);
  }

  &__head {
    display: flex;
    height: auto;
    justify-content: center;
    width: 100%;
  }
  .body {
    display: block;
    padding: u.rem(32);
    text-align: center;
  }
  .about-text {
    font-size: u.rem(14);
    word-break: break-all;
  }

  &__title {
    padding-left: u.rem(24);
    padding-top: u.rem(24);

    font-size: u.rem(14);
    @include u.breakpoint(medium) {
      font-size: u.rem(14);
    }
  }
}
</style>