<template>
  <section class="contact">
    <div
      v-if="loading"
      id="progressBar"
      class="progress-bar--container visible"
    ></div>
    <div class="contact__head">
      <h2 class="contact__title">"TALK TO OUR HOLIDAY CONSULTANT"</h2>
    </div>
    <div class="contact__grid">
      <div class="contact__lottie">
        <Vue3Lottie
          animationLink="/data/world-lottie.json"
          background="transparent"
          :speed="1"
          class="contact__lottie-file"
          loop
          autoplay
        ></Vue3Lottie>
      </div>
      <div class="contact_details">
        <div class="contact__body">
          <form @submit.prevent="handleSubmit">
            <div class="group">
              <input v-model="name" type="text" required="required" /><span
                class="highlight"
              ></span
              ><span class="bar"></span>
              <label>Tell us your name</label>
            </div>
            <div class="group">
              <input v-model="email" type="email" required="required" /><span
                class="highlight"
              ></span
              ><span class="bar"></span>
              <label>Your email address</label>
            </div>
            <div class="group">
              <input
                v-model="number"
                type="tel"
                id="phone"
                name="phone"
                maxlength="10"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                required
              /><span class="highlight"></span><span class="bar"></span>
              <label>Your contact number</label>
            </div>

            <Vue3Lottie
              v-if="submitted"
              animationLink="/data/ok.json"
              background="transparent"
              :speed="1"
              class="contact__lottie-ok"
              :loop="1"
              autoplay
            ></Vue3Lottie>
            <button
              :disabled="loading"
              class="contact__button"
              :class="loading ? 'contact__button-disabled' : ''"
            >
              Submit Details
            </button>
          </form>
        </div>
      </div>
      <div class="contact__details">
        <div class="contact__address">
          <div class="contact__address-title-div">
            <h2 class="contact__address-title">
              <i class="fa-solid fa-map icon"></i>Address
            </h2>
          </div>

          <p class="contact__address-text">
            {{ contact.Address }}
          </p>
        </div>

        <button
          class="contact__details-button"
          v-for="i in contact.emails"
          :key="i"
        >
          <i class="fa-solid fa-envelope"></i>
          {{ i }}
        </button>
        <button
          class="contact__details-button"
          v-for="i in contact.numbers"
          :key="i"
        >
          <i class="fa-solid fa-phone-volume"></i>
          {{ i }}
        </button>
      </div>
    </div>
  </section>

  <!-- <component
    :is="script"
    src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
    async
  ></component> -->
</template>

<script>
import { ref } from "vue";
import { db } from "../firebase/config";
import { collection, getDocs, doc, setDoc } from "firebase/firestore";

export default {
  async setup() {
    const contact = ref({});
    const name = ref("");
    const number = ref("");
    const email = ref("");
    const loading = ref(false);
    const submitted = ref(false);

    const getContact = async () => {
      const result = [];
      const querySnapshot = await getDocs(collection(db, "ContactDetails"));
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log("Contact details doc");
        console.log(doc.id, " => ", doc.data());
        result.push(doc.data());
      });
      contact.value = result[0];
    };

    const handleSubmit = async () => {
      if (submitted.value === false) {
        loading.value = true;
          let ide = Date.now().toString();

        const enquiry = {
          name: name.value,
          number: number.value,
          email: email.value,
          id:ide
        };

        // Add a new document with a generated id
        const enq = doc(collection(db,"Enquiry"),ide);

        const res = await setDoc(enq, enquiry);
        console.log(res);
        loading.value = false;
        submitted.value = true;
      }
    };
    getContact();
    return { contact, name, number, email, handleSubmit, loading, submitted };
  },
};
</script>

<style lang="scss" scoped>
@forward "../../scss/globals";
@use "../../scss/util" as u;
.contact {
  width: min(100% - u.rem(16), u.rem(1600));
  height: auto;
  margin-inline: auto;
  margin-top: u.rem(32);

  border-top-left-radius: u.rem(25);
  border-top-right-radius: u.rem(25);
  border-bottom-left-radius: u.rem(25);
  border-bottom-right-radius: u.rem(25);
  margin-bottom: u.rem(8);
  background-color: white;

  @include u.breakpoint(medium) {
    width: min(100% - u.rem(40), u.rem(1600));
    //   margin-top: -3%;
    // padding: u.rem(18);
  }
  &__body {
    // display: grid;
    // grid-template-columns: 100%;
    // grid-template-rows: auto;
    // gap: u.rem(8);
    width: 100%;

    padding: u.rem(16);
    margin-inline: auto;
    text-align: center;
  }

  &__grid {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    gap: u.rem(8);
    width: 100%;
    padding: u.rem(16);
    margin-inline: auto;
    @include u.breakpoint(medium) {
      grid-template-columns: 20% 40% 40%;
      grid-template-rows: auto;
    }

    @include u.breakpoint(large) {
      grid-template-columns: 33.33% 33.33% 33.33%;
      grid-template-rows: auto;
    }
  }

  &__lottie {
    width: 100%;
    height: 25vh;
    padding: u.rem(16);

    @include u.breakpoint(medium) {
      height: auto;
    }
  }
  &__lottie-ok {
    width: u.rem(100);
    height: u.rem(100);
    padding: u.rem(16);
    margin: auto;
  }
  &__lottie-file {
    width: 80%;
    height: 80%;
    margin: auto;
    // @include u.breakpoint(large) {

    // }
  }
  &__head {
    display: flex;
    height: auto;
    justify-content: center;
    width: 100%;
  }
  &__title {
    //padding-left: u.rem(24);
    padding-top: u.rem(32);
    padding-bottom: u.rem(32);
    text-align: center;
    padding-left: u.rem(16);
    padding-right: u.rem(16);

    font-size: u.rem(14);

    @include u.breakpoint(medium) {
      font-size: u.rem(16);
    }
  }
  &__button {
    padding: u.rem(16);
    margin: u.rem(16);
    background: linear-gradient(180deg, #802a38 0%, #be5564 50%, #802a38 100%);
    font-size: u.rem(12);
    border-radius: u.rem(50);
    box-shadow: 0px, 0px 0px #41414132;
    border: none;
    font-family: u.$font;
    font-weight: 600;
    color: var(--card-color-on-primary);
    padding-left: u.rem(24);
    padding-right: u.rem(24);
    margin-bottom: u.rem(16);

    @include u.breakpoint(medium) {
      font-size: u.rem(14);
    }
  }
  &__button-disabled {
    background: #d8d8d8;

    color: rgb(158 158 158);
  }
  &__details {
    padding: u.rem(16);
    margin-inline: auto;
    text-align: left;
  }
  &__address {
    margin-top: 5%;
    margin-inline: auto;
    text-align: center;
  }
  &__address-title-div {
    width: 100%;
    text-align: start;
  }
  &__address-title {
    padding-left: u.rem(8);
  }
  &__address-text {
    font-size: u.rem(16);
    text-align: left;
  }
  &__details-button {
    padding: u.rem(16);
    margin: u.rem(16);
    background: rgb(234, 234, 234);
    font-size: u.rem(12);
    border-radius: u.rem(50);
    box-shadow: 0px, 0px 0px #41414132;
    border: none;
    font-family: u.$font;
    font-weight: 600;
    color: var(--text-color);
    padding-left: u.rem(24);
    padding-right: u.rem(24);
    margin-bottom: u.rem(16);

    @include u.breakpoint(medium) {
      font-size: u.rem(14);
    }
  }

  .group {
    position: relative;
    margin: u.rem(42) u.rem(42);

    @include u.breakpoint-down(large) {
      margin: u.rem(32) u.rem(8);
    }
  }

  // INPUTS // ============================== //
  $bg-color: #424242;
  $hl-color: var(--color-primary);
  $muted-color: mix(white, $bg-color, 50%);
  $trans-time: 300ms;
  $width: 100%;
  textarea {
    resize: none;
  }

  input,
  textarea {
    background: none;
    color: $muted-color;
    font-size: u.rem(16);
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $muted-color;
    &:focus {
      outline: none;
    }
    &:focus ~ label,
    &:valid ~ label {
      top: -14px;
      font-size: 12px;
      color: $hl-color;
    }
    &:focus ~ .bar:before {
      width: $width;
    }
  }

  input[type="password"] {
    letter-spacing: 0.3em;
  }

  label {
    color: $muted-color;
    font-size: u.rem(16);
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: u.rem(5);
    top: u.rem(10);
    transition: $trans-time ease all;
  }

  .bar {
    position: relative;
    display: block;
    width: 100%;
    &:before {
      content: "";
      height: 2px;
      width: 0;
      bottom: 0px;
      position: absolute;
      background: $hl-color;
      transition: $trans-time ease all;
      left: 0%;
    }
  }

  .progress-bar--container {
    width: 90%;
    margin: auto;
    height: 2px;
    background-color: rgb(230, 230, 230);

    display: none;
    position: relative;
    overflow: hidden;
    transition: opacity 0.1s ease-out;
    opacity: 1;
  }

  .progress-bar--container::after {
    background-color: var(--color-primary);

    content: "";
    position: absolute;
    width: inherit;
    height: inherit;
    transform-origin: left;
  }

  .progress-bar--container.visible {
    display: block;
    animation: progress-bar--container_fadeIn 0.2s ease-in;
  }

  .progress-bar--container.visible::after {
    display: block;
    animation: progress-bar_fill 2s linear;
    animation-iteration-count: infinite;
  }

  .progress-bar--container.visible.fade {
    opacity: 0;
  }

  @keyframes progress-bar_fill {
    0% {
      transform: scaleX(0) translateX(0);
    }
    1% {
      transform: scaleX(0) translateX(0);
    }
    33% {
      transform: scaleX(0.66) translateX(16.5%);
    }
    75% {
      transform: scaleX(1.5) translateX(66%);
    }
    100% {
      transform: scaleX(2) translateX(150%);
    }
  }
  @keyframes progress-bar--container_fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}
</style>