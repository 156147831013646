<template>
  <div class="topDestination">
    <div class="topDestination__cover">
      <img class="topDestination__img" :src="activeDestination.mainImage" />
    </div>
    <div class="topDestination__grid">
      <div class="topDestination__details">
        <div class="topDestination__details-flex-container">
          <div class="topDestination__details-shortDetails-left">
            <div class="topDestination__div-short-details">
              <h2 class="topDestination__name">
                <!-- <i class="fa-solid fa-mountain place-icon"></i> -->
                <i
                  v-if="activeDestination.destinationType == 'Mountain'"
                  class="fa-solid fa-mountain place-icon"
                />
                <i
                  v-if="activeDestination.destinationType == 'Heritage'"
                  class="fa-solid fa-landmark-dome place-icon"
                />
                <i
                  v-if="activeDestination.destinationType == 'Beach'"
                  class="fa-solid fa-umbrella-beach place-icon"
                />
                <i
                  v-if="activeDestination.destinationType == 'City'"
                  class="fa-solid fa-city place-icon"
                />
                <i
                  v-if="activeDestination.destinationType == 'Jungle'"
                  class="fa-solid fa-tree place-icon"
                ></i>

                <i
                  v-if="activeDestination.destinationType == 'Riverside'"
                  class="fa-solid fa-water place-icon"
                />
                {{ activeDestination.name }}
              </h2>
              <h3 class="topDestination__attractions">
                <i class="fa-solid fa-map-location-dot attraction-icon"></i
                >{{ activeDestination.numberOfAttraction }} Attractions
              </h3>
              <h3 class="topDestination__hotel">
                <i class="fa-solid fa-hotel hotel-icon"></i>
                {{ activeDestination.numberOfHotels }} Hotel / Resorts
              </h3>
            </div>
          </div>
          <div class="topDestination__details-shortDetails-right">
            <div class="topDestination__div-view-all-col-1">
              <router-link
                :to="{
                  name: 'DestinationDetails',
                  params: { id: activeDestination.id },
                }"
              >
                <button class="topDestination__button-viewAllDestinations">
                  View Details
                </button>
              </router-link>
            </div>
          </div>
        </div>

        <div class="topDestination__div-description">
          <p class="topDestination__description">
            {{ activeDestination.desc }}
          </p>
        </div>
      </div>
      <div class="topDestination__attractions-div">
        <div class="topDestination__attractions-flex-container">
          <h2 class="topDestination__attractions-title">Attractions</h2>

          <router-link
            :to="{
              name: 'DestinationDetails',
              params: { id: activeDestination.id },
            }"
          >
            <button class="topDestination__button-viewAllDestinations-attr">
              View Details
            </button>
          </router-link>
        </div>

        <div class="topDestination__attractions-container">
          <AttractionItem
            v-for="doc in activeDestination.attractionIds"
            :key="doc.id"
            :attraction="doc"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AttractionItem from "@/components/small/AttractionItem.vue";
import { ref } from "vue";

const loadHeader = async () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        name: "Fake work",
      });
    }, 1000);
  });
};

export default {
  props: ["activeDestination"],
  components: {
    AttractionItem,
  },

  async setup() {
    const userData = ref(await loadHeader());
    return {
      userData,
    };
  },
};
</script>

<style lang="scss">
@forward "../../../scss/globals";
@use "../../../scss/util" as u;

.topDestination {
  position: relative;
  width: 100%;
  height: auto;
  float: left;
  overflow: hidden;

  &__button-viewAllDestinations {
    align-self: flex-end;
    margin-top: u.rem(16);
    margin-right: u.rem(0);
    padding-left: u.rem(12);
    padding-right: u.rem(12);
    padding-top: u.rem(8);
    padding-bottom: u.rem(8);
    margin-bottom: u.rem(16);
    font-size: u.rem(12);
    border: 2px solid var(--color-primary);
    background-color: white;
    color: var(--color-primary);
    padding-bottom: u.rem(8);
    border-radius: u.rem(50);
    @include u.breakpoint(medium) {
      font-size: u.rem(14);
    }
    @include u.breakpoint(large) {
      visibility: hidden;
    }
  }
  &__button-viewAllDestinations-attr {
    visibility: collapse;
    align-self: flex-end;
    margin-top: u.rem(8);
    // margin-right: u.rem(0);
    padding-left: u.rem(12);
    padding-right: u.rem(12);
    padding-top: u.rem(8);
    padding-bottom: u.rem(8);
    margin-bottom: u.rem(8);
    font-size: u.rem(12);
    border: 2px solid var(--color-primary);
    background-color: white;
    color: var(--color-primary);
    padding-bottom: u.rem(8);
    border-radius: u.rem(50);
    @include u.breakpoint(medium) {
      font-size: u.rem(14);
    }
    @include u.breakpoint(large) {
      visibility: visible;
    }
  }

  &__container {
    position: relative;
    //width: 100% * 4;
    width: 100%;
    // height: 90vh;
    top: 0;
    left: 0;
    overflow: hidden;

    // animation: slider 26s infinite;
  }

  &__cover {
    display: block;
    width: 100%;
    height: 50vh;
  }

  &__img {
    display: block;
    width: 100%;
    height: 50vh;
    object-fit: cover;
  }

  &__details-flex-container {
    display: flex;
    height: auto;
    justify-content: space-between;
    width: 100%;
  }

  &__attractions-flex-container {
    display: flex;
    height: auto;
    justify-content: space-between;
    width: 100%;
  }

  &__attractions-title {
    font-size: u.rem(14);
    padding-top: u.rem(12);
    padding-bottom: u.rem(12);
    margin-top: auto;
    margin-bottom: auto;
    @include u.breakpoint(medium) {
      font-size: u.rem(14);
    }
  }

  &__details-shortDetails-right {
    display: contents;
    align-self: flex-end;
    height: auto;
  }

  &__grid {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    gap: u.rem(8);
    width: 100%;
    padding: u.rem(16);
    margin-inline: auto;
    @include u.breakpoint(large) {
      grid-template-columns: 50% 50%;
      grid-template-rows: auto;
    }
  }

  &__name {
    font-size: u.rem(14);
  }

  &__attractions {
    font-size: u.rem(12);
    font-weight: 500;
    @include u.breakpoint(medium) {
      font-size: u.rem(14);
    }
  }

  &__hotel {
    font-size: u.rem(12);
    font-weight: 500;
    width: 100%;
    @include u.breakpoint(medium) {
      font-size: u.rem(14);
    }
  }

  &__description {
    font-size: u.rem(11);
    font-weight: 400;
    word-wrap: break-word;

    @include u.breakpoint(medium) {
      font-size: u.rem(14);
    }
  }

  .place-icon {
    color: var(--color-primary);
    width: u.rem(14);
    height: u.rem(14);
    margin-right: u.rem(8);
  }

  .attrtaction-icon {
    color: var(--color-primary);
    width: u.rem(12);
    height: u.rem(12);
    margin-right: u.rem(8);
  }

  .attraction-icon {
    color: var(--color-primary);
    width: u.rem(12);
    height: u.rem(12);
    margin-right: u.rem(8);
  }
  .hotel-icon {
    color: var(--color-primary);
    width: u.rem(12);
    height: u.rem(12);
    margin-right: u.rem(8);
  }

  &__attractions-container {
    display: flex;
    height: 100%;
    width: 90vw;
    margin-inline: auto;
    overflow-x: scroll;
  }
  &__attraction-div {
    display: inline-block;
    // width: u.rem(108);
    margin-right: u.rem(16);
    vertical-align: middle;
    height: 100%;
  }
  &__attraction-img {
    margin: u.rem(8);
    width: u.rem(100);
    height: u.rem(100);
    border-radius: u.rem(10);
  }
  &__attraction-name {
    font-size: u.rem(12);
    text-align: center;
    width: 100%;
    font-weight: 500;
  }
}
</style>