<template>
  <Suspense>
    <template #default>
       <Header />
    </template>
    <template #fallback>
      <HeaderSkeleton />
    </template>
  </Suspense>

 
  <router-view />
  <Footer />
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import HeaderSkeleton from "@/components/skeletons/HeaderSkeleton.vue"


export default {
  name: "HomeView",
  components: {
    Header,
    HeaderSkeleton,
    Footer,
    
  },
  setup() {
    


   
    

   
  },
};
</script>



<style lang="scss">
@forward "../scss/globals";
@use "../scss/util" as u;
</style>
