<template>
  <router-link
    :to="{
      name: 'AttractionDetails',
      params: { id: attraction.id },
    }"
  >
    <div class="attraction-div">
      <img class="attraction-img" :src="attraction.icon" />
      <h2 class="attraction-name">{{ attraction.name }}</h2>
    </div>
  </router-link>
</template>

<script>
export default {
  props: ["attraction"],
};
</script>

<style lang="scss">
@forward "../../../scss/globals";
@use "../../../scss/util" as u;

.attraction-div {
  display: inline-block;
  // width: u.rem(108);
  margin-right: u.rem(16);
  vertical-align: middle;
  height: 100%;
}
.attraction-img {
  display: block;
  margin-top: u.rem(8);
  margin-bottom: u.rem(16);
  margin-left: auto;
  margin-right: auto;
  width: u.rem(100);
  height: u.rem(100);
  border-radius: u.rem(10);
}
.attraction-name {
  font-size: u.rem(12);
  text-align: center;
  width: 100%;
  font-weight: 500;
}
</style>