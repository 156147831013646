<template>
  <div class="our-partners">
    <div class="our-partners__head">
      <h2 class="our-partners__title">"OUR PARTNERS"</h2>
    </div>
    <div class="our-partners__body">
      <div class="our-partners__cont">
        <div class="scrl primary">
            <img src="/data/brands/lemon-tree.png" alt="" class="our-partners__item" />
        <img src="/data/brands/holiday-inn.png" alt="" class="our-partners__item" />
        <img src="/data/brands/radison.png" alt="" class="our-partners__item" />
        <img src="/data/brands/ramada.png" alt="" class="our-partners__item" />
        <img src="/data/brands/ren.png" alt="" class="our-partners__item" />
        <img
          src="/data/brands/four-seasons.png"
          alt=""
          class="our-partners__item"
        />
        <img
          src="/data/brands/sheraton.png"
          alt=""
          class="our-partners__item"
        />
        <img
          src="/data/brands/anantara.png"
          alt=""
          class="our-partners__item"
        />
        <img src="/data/brands/wyndham.png" alt="" class="our-partners__item" />
        <img
          src="/data/brands/meridien.png"
          alt=""
          class="our-partners__item"
        />
        <img src="/data/brands/hilton.png" alt="" class="our-partners__item" />

        </div>
        <div class="scrl secondary">
            <img src="/data/brands/lemon-tree.png" alt="" class="our-partners__item" />
        <img src="/data/brands/holiday-inn.png" alt="" class="our-partners__item" />
        <img src="/data/brands/radison.png" alt="" class="our-partners__item" />
        <img src="/data/brands/ramada.png" alt="" class="our-partners__item" />
        <img src="/data/brands/ren.png" alt="" class="our-partners__item" />
        <img
          src="/data/brands/four-seasons.png"
          alt=""
          class="our-partners__item"
        />
        <img
          src="/data/brands/sheraton.png"
          alt=""
          class="our-partners__item"
        />
        <img
          src="/data/brands/anantara.png"
          alt=""
          class="our-partners__item"
        />
        <img src="/data/brands/wyndham.png" alt="" class="our-partners__item" />
        <img
          src="/data/brands/meridien.png"
          alt=""
          class="our-partners__item"
        />
        <img src="/data/brands/hilton.png" alt="" class="our-partners__item" />

        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped >
@forward "../../scss/globals";
@use "../../scss/util" as u;

.our-partners {
  position: relative;
  width: min(100% - u.rem(16), u.rem(1600));
  height: auto;
  margin-inline: auto;
  margin-top: u.rem(32);

  border-top-left-radius: u.rem(25);
  border-top-right-radius: u.rem(25);
  border-bottom-left-radius: u.rem(25);
  border-bottom-right-radius: u.rem(25);
  margin-bottom: u.rem(8);
  background-color: white;

  @include u.breakpoint(medium) {
    width: min(100% - u.rem(40), u.rem(1600));
    //   margin-top: -3%;
    padding: u.rem(18);
  }

  .scrl{
     width: inherit;
  height: inherit;
  position: absolute;
  left: 0%;
  top: 0%;


  }

  &__head {
    display: flex;
    height: auto;
    justify-content: center;
    width: 100%;
  }
  &__title {
    //padding-left: u.rem(24);
    padding-top: u.rem(32);
    padding-bottom: u.rem(32);
    text-align: center;
    padding-left: u.rem(16);
    padding-right: u.rem(16);

    font-size: u.rem(14);

    @include u.breakpoint(medium) {
      font-size: u.rem(16);
    }
  }
  &__item {
     position: relative;
    display: inline-block;
    width: u.rem(250);
    
    
    margin-top:auto;
    margin-bottom: auto;
    margin-right: u.rem(32);
     margin-left: u.rem(32);
  }

  &__body {
   position: relative;
   height: u.rem(150);
    overflow: hidden;
      margin-inline: auto;
    width: 95%;
    margin: auto;
    margin-top: u.rem(32);
    overflow: hidden;
  }

  &__cont {
    position: absolute;
    display: flex;
    height: u.rem(150);
     width: u.rem(3454);
    overflow: hidden;
    top: 0;
    left: 0;
    
  
  }

.primary {
  animation: primary 20s linear infinite;
}

.secondary {
  animation: secondary 20s linear infinite;
}

@keyframes primary {
  from {
    left: 0%;
  }
  to {
    left: -100%;
  }
}

@keyframes secondary {
  from {
    left: 100%;
  }
  to {
    left: 0%;
  }
}

   
}
</style>