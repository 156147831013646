<template>
  <div class="header-skeleton">
    <div class="header__col-1-social">
      <!-- todo -->
    </div>

    <div class="header-skeleton__col-2-logo">
      <div class="header-skeleton__logo-container">
        <div class="skeleton-box circular"></div>
      </div>
    </div>

    <div class="header-skeleton__col-3-mobilenav"></div>

    <div class="header-skeleton__col-4-contact"></div>
  </div>
  <div class="nav-skeleton">
    <div class="nav-skeleton__wrapper">
      <div class="nav-skeleton__li">
        <div class="skeleton-box cir"></div>
      </div>
      <div class="nav-skeleton__li">
        <div class="skeleton-box cir"></div>
      </div>
      <div class="nav-skeleton__li">
        <div class="skeleton-box cir"></div>
      </div>
      <div class="nav-skeleton__li">
        <div class="skeleton-box cir"></div>
      </div>
      <div class="nav-skeleton__li">
        <div class="skeleton-box cir"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style  lang="scss" >
@forward "../../../scss/globals";
@use "../../../scss/util" as u;

.header-skeleton {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: u.rem(8);
  width: min(100% - u.rem(40), u.rem(1600));
  margin-inline: auto;

  &__col-1-social {
    display: block;
  }

  &__col-2-logo {
    display: block;
    margin: auto;
  }

  &__col-3-mobilenav {
    display: block;
    //margin-right:auto;
    margin: auto;
    margin-right: 0%;

    @include u.breakpoint(medium) {
      display: none;
    }
  }

  &__col-4-contact {
    display: block;
    margin: auto;
    @include u.breakpoint-down(small) {
      display: none;
    }
  }

  &__logo-container {
    width: calc(70px + 2vw);
    height: calc(70px + 2vw);
  }
  .circular {
    border-radius: 100px;
  }
}
.nav-skeleton {
  height: calc(30px + 2vh);
  transition: width 0.3s ease-in-out;
  background-color: white;
  border-radius: 3.125rem;
  width: min(100% - 2.5rem, 100rem);
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: u.rem(8);

  &__wrapper {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    list-style: none;
    margin: 0 auto;
    height: calc(30px + 2vh);
    text-decoration: none;

    padding: 0px;
  }
  &__li {
    display: flex;
    width: 100%;

    height: 100%;
  }
  .cir {
    width: 80%;
    height: 80%;
    border-radius: 100px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>