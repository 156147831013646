<template>
  <section class="gallery">
    <div class="gallery__head">
      <h2 class="gallery__title">GALLERY</h2>
    </div>
    <div class="gallery__slider">
      <div class="gallery__container">
        <div class="v gallery__slide">
          <div class="gallery__cover">
            <img class="gallery__img" :src="activeImage" />
          </div>
        </div>
        
      </div>
    </div>
    <div class="gallery__seek-div">
      <div class="gallery__seek-container">
        <div v-for="doc in gallery.imagesRef"
          :key="doc" @click = "setImageActive(doc)" class="gallery__seek-item">
          <img
            :src="doc"
            alt="image"
            class="gallery__seek-thumbnail "
            :class="doc == activeImage ? 'gallery__selected-thumbnail' : ''"
          />
        </div>
       
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from "vue";
import { db } from "../firebase/config";
import { collection, getDocs } from "firebase/firestore";

export default {
    


  async setup() {
     const gallery = ref({});
     const activeImage = ref([]);

    const getGallery = async () => {
      const result = [];
      const querySnapshot = await getDocs(collection(db, "Gallery"));
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        result.push(doc.data())
      })
      gallery.value = result[0];
      console.log("result ")
          console.log(result )
      activeImage.value = result[0].imagesRef[0]
      console.log("Active image")
      console.log(activeImage.value)
      
    };

    const setImageActive = async (value) =>{
        activeImage.value = value
        console.log("Clicked")
        console.log(value)


    }
    getGallery(gallery)

    return{gallery , activeImage, setImageActive }
  },
};
</script>

<style lang="scss">
@forward "../../scss/globals";
@use "../../scss/util" as u;

.gallery {
  width: min(100% - u.rem(16), u.rem(1600));
  height: auto;
  margin-inline: auto;
  margin-top: u.rem(32);

  border-top-left-radius: u.rem(25);
  border-top-right-radius: u.rem(25);
  border-bottom-left-radius: u.rem(25);
  border-bottom-right-radius: u.rem(25);
  margin-bottom: u.rem(8);
  background-color: white;

  @include u.breakpoint(medium) {
    width: min(100% - u.rem(40), u.rem(1600));
    //   margin-top: -3%;
    padding: u.rem(18);
  }

  &__slider {
    //  display:inline-block;
    position: relative;
    // height: 90vh;
    height: auto;
    overflow: hidden;
    border-top-left-radius: u.rem(25);
    border-top-right-radius: u.rem(25);
    border-bottom-left-radius: u.rem(25);
    border-bottom-right-radius: u.rem(25);
    @include u.breakpoint(large) {
      // height: 75vh;
    }
  }
  &__head {
    display: flex;
    height: auto;
    justify-content: space-between;
    width: 100%;
  }

  &__title {
    padding-left: u.rem(24);
    padding-top: u.rem(24);

    font-size: u.rem(14);
    @include u.breakpoint(medium) {
      font-size: u.rem(14);
    }
  }

  &__container {
    position: relative;
    width: 100%;
    // height: 90vh;
    top: 0;
    left: 0;
    overflow: hidden;


  }

  &__slide {
    position: relative;
    width: 100%;
    height: auto;
    float: left;
    overflow: hidden;
  }

  &__cover {
    display: block;
    width: 100%;
    height: 50vh;
  }

  &__img {
    display: block;
    width: 100%;
    height: 50vh;
    object-fit: cover;
  }
  &__seek-div {
    padding: u.rem(16);
  }
  &__seek-container {
    display: flex;
    height: 100%;
    width: 90vw;
    margin-inline: auto;
    overflow-x: scroll;
  }
  &__seek-thumbnail {
    width: u.rem(80);
    height: u.rem(80);
    margin: u.rem(16);
    border-radius: u.rem(10);
  }
  &__selected-thumbnail {
    border: u.rem(5) solid var(--color-primary);
    width: u.rem(100);
    height: u.rem(100);
  }
}
</style>