<template>
  <Suspense>
    <template #default>
      <TopSlider />
    </template>

    <template #fallback>
      <TopSliderSkeleton />
    </template>
  </Suspense>

  <Suspense>
    <template #default>
      <TopDestinations />
    </template>

    <template #fallback>
      <TopDestinationSkeleton />
    </template>
  </Suspense>

  <FindDestinations />

   <Suspense>
    <template #default>
      <Gallery/>
    </template>

    <template #fallback>
     <h1>loding</h1>
    </template>
  </Suspense>

  <AboutUs/>

  <Suspense>
    <template #default>
      <Reviews/>
    </template>

    <template #fallback>
     <h1>loding</h1>
    </template>
  </Suspense>
  <Suspense>
    <template #default>
     <OurPartners/>
    </template>

    <template #fallback>
     <h1>loding</h1>
    </template>
  </Suspense>

  <Suspense>
    <template #default>
     <ContactUs/>
    </template>

    <template #fallback>
     <h1>loding</h1>
    </template>
  </Suspense>

  
  
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import TopSlider from "@/components/TopSlider.vue";
import TopSliderSkeleton from "@/components/skeletons/TopSliderSkeleton.vue";

import TopDestinations from "@/components/TopDestinations.vue";
import TopDestinationSkeleton from "@/components/skeletons/TopDestinationSkeleton.vue";
import FindDestinations from "@/components/FindDestinations.vue";
import Gallery from "@/components/Gallery.vue";
import Reviews from "@/components/Reviews.vue";
import ContactUs from "@/components/ContactUs.vue";
import OurPartners from "@/components/OurPartners.vue";
import AboutUs from "@/components/AboutUs.vue";

export default {
  name: "HomeView",
  components: {
    TopSlider,
    TopSliderSkeleton,

    TopDestinations,
    TopDestinationSkeleton,

    FindDestinations,
    Gallery,
    Reviews,
    ContactUs,
    OurPartners,
    AboutUs
  },
};
</script>
<style lang="scss">
</style>
