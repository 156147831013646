<template>
  <div class="skleton-top-destinations__cover">
      <div class="skleton-top-destinations__skeleton-box-f"></div>
    </div>
    <div class="skleton-top-destinations__grid">
      <div class="skleton-top-destinations__details">
        <div class="skleton-top-destinations__details-flex-container">
          <div class="skleton-top-destinations__details-left">
            <div
              class="
                skleton-top-destinations__name
                skleton-top-destinations__skeleton-box
              "
            ></div>
            <div
              class="
                skleton-top-destinations__attr
                skleton-top-destinations__skeleton-box
              "
            ></div>
            <div
              class="
                skleton-top-destinations__hotel
                skleton-top-destinations__skeleton-box
              "
            ></div>
          </div>

          <div class="skleton-top-destinations__details-right">
            <div
              class="
                skleton-top-destinations__m-view-but
                skleton-top-destinations__skeleton-box
              "
            ></div>
          </div>
        </div>
        <div
          class="
            skleton-top-destinations__description
            skleton-top-destinations__skeleton-box
          "
        ></div>
      </div>

      <div class="skleton-top-destinations__attraction-div">
        <div class="skleton-top-destinations__attr-head">
          <div
            class="
              skleton-top-destinations__title
              skleton-top-destinations__skeleton-box
            "
          ></div>
          <div
            class="
              skleton-top-destinations__w-view-but
              skleton-top-destinations__skeleton-box
            "
          ></div>
        </div>
        <div class="skleton-top-destinations__attr-item-container">
          <div
            class="
              skleton-top-destinations__attr-item
              skleton-top-destinations__skeleton-box
            "
          ></div>
          <div
            class="
              skleton-top-destinations__attr-item
              skleton-top-destinations__skeleton-box
            "
          ></div>
          <div
            class="
              skleton-top-destinations__attr-item
              skleton-top-destinations__skeleton-box
            "
          ></div>
          <div
            class="
              skleton-top-destinations__attr-item
              skleton-top-destinations__skeleton-box
            "
          ></div>
          
        </div>
      </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>