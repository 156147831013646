<template>
  <section class="topDestinations">
    <div class="topDestinations__head">
      <h2 class="topDestinations__title">TOP DESTINATIONS</h2>

      <router-link
        :to="{
          name: 'holidayAndExperiences',
          params: { all: true, continent: 'ALL' },
        }"
      >
        <button class="topDestinations__button-viewAll">View All</button>
      </router-link>
    </div>

    <div class="topDestinations__head">
      <ul class="topDestinations__links">
        <li
          class="topDestinations__link"
          :class="doc.id === activeDestination.id ? 'activeTopDest' : ''"
          v-for="doc in topDestinations"
          :key="doc.id"
          @click="setActiveDest(doc.id)"
        >
          <i v-if="doc.destinationType == 'Mountain'" class="fa-solid fa-mountain place-icon" />
          <i  v-if="doc.destinationType == 'Heritage'" class="fa-solid fa-landmark-dome place-icon" />
          <i  v-if="doc.destinationType == 'Beach'" class="fa-solid fa-umbrella-beach place-icon" />
          <i  v-if="doc.destinationType == 'City'" class="fa-solid fa-city place-icon" />
          <i  v-if="doc.destinationType == 'Jungle'" class="fa-solid fa-tree place-icon"></i>
          
          <i  v-if="doc.destinationType == 'Riverside'" class="fa-solid fa-water place-icon" />

          {{ doc.name }}
        </li>
      </ul>
    </div>

    <div class="topDestinations__slider">
      <div class="topDestinations__container">
        <Suspense>
          <template #default>
            <TopDestination :activeDestination="activeDestination" />
          </template>
          <template #fallback>
            <TopDestinationSkeleton />
          </template>
        </Suspense>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from "vue";
import { db } from "../firebase/config";
import { collection, query, where, getDocs } from "firebase/firestore";
import AttractionItem from "@/components/small/AttractionItem.vue";
import TopDestination from "@/components/small/TopDestination.vue";
import TopDestinationSkeleton from "@/components/skeletons/TopDestinatioSleleton.vue";

const loadHeader = async () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        name: "Fake wait",
      });
    }, 1000);
  });
};

export default {
  components: {
    AttractionItem,
    TopDestination,
    TopDestinationSkeleton,
  },
  async setup() {
    const topDestinations = ref([]);
    const loaded = ref(false);
    const activeDestination = ref({});

    const getTopDest = async () => {
      const result = [];

      // Create a reference to the cities collection

      const topDestRef = collection(db, "Destinations");

      // Create a query against the collection.
      const q = await query(topDestRef, where("topDest", "==", true));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        result.push(doc.data());
      });

      topDestinations.value = result;
      console.log("This is s s s");
      console.log(result);
      activeDestination.value = result[0];
    };

    getTopDest();

    const setActiveDest = async (value) => {
      var result = topDestinations.value.filter((obj) => {
        return obj.id === value;
      });
      console.log("Found it");
      console.log(result);
      activeDestination.value = result[0];
    };

    const userData = ref(await loadHeader());

    return {
      topDestinations,
      loaded,
      activeDestination,
      setActiveDest,
      userData,
    };
  },
};
</script>

<style lang="scss">
@forward "../../scss/globals";
@use "../../scss/util" as u;

.topDestinations {
  width: min(100% - u.rem(16), u.rem(1600));
  height: auto;
  margin-inline: auto;
  margin-top: u.rem(32);

  border-top-left-radius: u.rem(25);
  border-top-right-radius: u.rem(25);
  border-bottom-left-radius: u.rem(25);
  border-bottom-right-radius: u.rem(25);
  margin-bottom: u.rem(8);
  background-color: white;

  @include u.breakpoint(medium) {
    width: min(100% - u.rem(40), u.rem(1600));
    //   margin-top: -3%;
    padding: u.rem(18);
  }

  &__slider {
    //  display:inline-block;
    position: relative;
    // height: 90vh;
    height: auto;
    overflow: hidden;
    border-top-left-radius: u.rem(25);
    border-top-right-radius: u.rem(25);
    border-bottom-left-radius: u.rem(25);
    border-bottom-right-radius: u.rem(25);
    @include u.breakpoint(large) {
      // height: 75vh;
    }
  }
  li:hover {
    color: var(--color-primary);
    cursor: pointer;
    font-weight: 800;
  }

  &__head {
    display: flex;
    height: auto;
    justify-content: space-between;
    width: 100%;
  }
  &__links {
    display: flex;
    // width: 100%;
    // justify-content: space-around;
    margin-top: 0;

    align-items: center;
    list-style: none;
    // margin: 0 auto;
    height: calc(30px + 2vh);
    text-decoration: none;
    text-transform: uppercase;
    padding: 0px;
    //display: flex;
    //height: 100%;
    width: 90vw;
    margin-inline: auto;
    overflow-x: scroll;
  }

  &__link {
    display: flex;
    padding-left: u.rem(16);
    padding-right: u.rem(16);
    padding-top: u.rem(8);
    padding-bottom: u.rem(8);
    width: auto;
    align-items: center;
    border: 1px solid var(--text-color);
    border-radius: u.rem(20);
    i {
      color: var(--text-color);
    }
  }

  .activeTopDest {
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
    i {
      color: var(--color-primary);
    }
  }

  //

 

  &__title {
    padding-left: u.rem(24);
    padding-top: u.rem(24);

    font-size: u.rem(14);
    @include u.breakpoint(medium) {
      font-size: u.rem(14);
    }
  }

  &__button-viewAll {
    align-self: flex-end;
    margin-top: u.rem(16);
    margin-right: u.rem(16);
    padding-left: u.rem(12);
    padding-right: u.rem(12);
    padding-top: u.rem(8);
    padding-bottom: u.rem(8);
    margin-bottom: u.rem(16);
    font-size: u.rem(12);
    border: 2px solid var(--color-primary);
    background-color: white;
    color: var(--color-primary);
    padding-bottom: u.rem(8);
    border-radius: u.rem(50);
    @include u.breakpoint(medium) {
      font-size: u.rem(14);
    }
  }
  &__button-viewAllDestinations {
    align-self: flex-end;
    margin-top: u.rem(16);
    margin-right: u.rem(0);
    padding-left: u.rem(12);
    padding-right: u.rem(12);
    padding-top: u.rem(8);
    padding-bottom: u.rem(8);
    margin-bottom: u.rem(16);
    font-size: u.rem(12);
    border: 2px solid var(--color-primary);
    background-color: white;
    color: var(--color-primary);
    padding-bottom: u.rem(8);
    border-radius: u.rem(50);
    @include u.breakpoint(medium) {
      font-size: u.rem(14);
    }
    @include u.breakpoint(large) {
      visibility: hidden;
    }
  }
  &__button-viewAllDestinations-attr {
    visibility: collapse;
    align-self: flex-end;
    margin-top: u.rem(8);
    // margin-right: u.rem(0);
    padding-left: u.rem(12);
    padding-right: u.rem(12);
    padding-top: u.rem(8);
    padding-bottom: u.rem(8);
    margin-bottom: u.rem(8);
    font-size: u.rem(12);
    border: 2px solid var(--color-primary);
    background-color: white;
    color: var(--color-primary);
    padding-bottom: u.rem(8);
    border-radius: u.rem(50);
    @include u.breakpoint(medium) {
      font-size: u.rem(14);
    }
    @include u.breakpoint(large) {
      visibility: visible;
    }
  }

  &__container {
    position: relative;
    //width: 100% * 4;
    width: 100%;
    // height: 90vh;
    top: 0;
    left: 0;
    overflow: hidden;

    // animation: slider 26s infinite;
  }

  &__slide {
    position: relative;
    width: 100%;
    height: auto;
    float: left;
    overflow: hidden;
  }

  &__cover {
    display: block;
    width: 100%;
    height: 50vh;
  }

  &__img {
    display: block;
    width: 100%;
    height: 50vh;
    object-fit: cover;
  }

  &__details-flex-container {
    display: flex;
    height: auto;
    justify-content: space-between;
    width: 100%;
  }

  &__attractions-flex-container {
    display: flex;
    height: auto;
    justify-content: space-between;
    width: 100%;
  }

  &__attractions-title {
    font-size: u.rem(14);
    padding-top: u.rem(12);
    padding-bottom: u.rem(12);
    margin-top: auto;
    margin-bottom: auto;
    @include u.breakpoint(medium) {
      font-size: u.rem(14);
    }
  }

  &__details-shortDetails-right {
    display: contents;
    align-self: flex-end;
    height: auto;
  }

  &__grid {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    gap: u.rem(8);
    width: 100%;
    padding: u.rem(16);
    margin-inline: auto;
    @include u.breakpoint(large) {
      grid-template-columns: 50% 50%;
      grid-template-rows: auto;
    }
  }

  &__name {
    font-size: u.rem(14);
  }

  &__attractions {
    font-size: u.rem(12);
    font-weight: 500;
    @include u.breakpoint(medium) {
      font-size: u.rem(14);
    }
  }

  &__hotel {
    font-size: u.rem(12);
    font-weight: 500;
    width: 100%;
    @include u.breakpoint(medium) {
      font-size: u.rem(14);
    }
  }

  &__description {
    font-size: u.rem(11);
    font-weight: 400;
    word-wrap: break-word;

    @include u.breakpoint(medium) {
      font-size: u.rem(14);
       word-wrap: break-word;
    }
  }

  .place-icon {
    color: var(--color-primary);
    width: u.rem(14);
    height: u.rem(14);
    margin-right: u.rem(8);
  }

  .attrtaction-icon {
    color: var(--color-primary);
    width: u.rem(12);
    height: u.rem(12);
    margin-right: u.rem(8);
  }

  .attraction-icon {
    color: var(--color-primary);
    width: u.rem(12);
    height: u.rem(12);
    margin-right: u.rem(8);
  }
  .hotel-icon {
    color: var(--color-primary);
    width: u.rem(12);
    height: u.rem(12);
    margin-right: u.rem(8);
  }

  &__attractions-container {
    display: flex;
    height: 100%;
    width: 90vw;
    margin-inline: auto;
    overflow-x: scroll;
  }
  &__attraction-div {
    display: inline-block;
    // width: u.rem(108);
    margin-right: u.rem(16);
    vertical-align: middle;
    height: 100%;
  }
  &__attraction-img {
    margin: u.rem(8);
    width: u.rem(100);
    height: u.rem(100);
    border-radius: u.rem(10);
  }
  &__attraction-name {
    font-size: u.rem(12);
    text-align: center;
    width: 100%;
    font-weight: 500;
  }
}
</style>